import http from "./http";
import { HTTP_SUCCESS } from "../utils/constants";

export default () => ({
  Preferences: (preferenceId) => ({
    async index(filters) {
      return http
        .index(`system/preferences`, filters)
        .then((json) => ({ preferences: json }))
        .catch(async (e) => ({ preferences: null, error: await e }));
    },
    async get() {
      return http
        .get(`system/preferences`, preferenceId)
        .then((json) => ({ preference: json }))
        .catch(async (e) => ({ preference: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`system/preferences`, data)
        .then((json) => ({ preference: json }))
        .catch(async (e) => ({ preference: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`system/preferences`, preferenceId, data)
        .then((json) => ({ preference: json }))
        .catch(async (e) => ({ preference: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`system/preferences`, preferenceId)
        .then((json) => ({ preference: json }))
        .catch(async (e) => ({ preference: null, error: await e }));
    },
  }),
  Printers: (printerId) => ({
    async index(filters) {
      return http
        .index(`printers`, filters)
        .then((json) => ({ printers: json }))
        .catch(async (e) => ({ printers: null, error: await e }));
    },
    async get() {
      return http
        .get(`printers`, printerId)
        .then((json) => ({ printer: json }))
        .catch(async (e) => ({ printer: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`printers`, data)
        .then((json) => ({ printer: json }))
        .catch(async (e) => ({ printer: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`printers`, printerId, data)
        .then((json) => ({ printer: json }))
        .catch(async (e) => ({ printer: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`printers`, printerId)
        .then((json) => ({ printer: json }))
        .catch(async (e) => ({ printer: null, error: await e }));
    },
    Job: (jobId) => ({
      async index(filters) {
        return http
          .index(`printers/${printerId}/jobs`, filters)
          .then((json) => ({ jobs: json }))
          .catch(async (e) => ({ jobs: null, error: await e }));
      },
      async get() {
        return http
          .get(`printers/${printerId}/jobs`, jobId)
          .then((json) => ({ job: json }))
          .catch(async (e) => ({ job: null, error: await e }));
      },
      async create(data) {
        return http
          .post(`printers/${printerId}/jobs`, data)
          .then((json) => ({ job: json }))
          .catch(async (e) => ({ job: null, error: await e }));
      },
      async update(data) {
        return http
          .put(`printers/${printerId}/jobs`, jobId, data)
          .then((json) => ({ job: json }))
          .catch(async (e) => ({ job: null, error: await e }));
      },
      async delete() {
        return http
          .delete(`printers/${printerId}/jobs`, jobId)
          .then((json) => ({ job: json }))
          .catch(async (e) => ({ job: null, error: await e }));
      },
    }),
  }),
  Logs: () => ({
    async index() {
      return http
        .index(`system/logs`)
        .then((json) => ({ logs: json }))
        .catch(async (e) => ({ logs: null, error: await e }));
    },
  }),
  Print: () => ({
    async Order(body) {
      return fetch("http://localhost/impressao.php", {
        method: "POST",
        body,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then((r) => {
        if (r.status === HTTP_SUCCESS) return r.json();
        else throw r.json();
      });
    },
    async Bill(body) {
      return fetch("http://localhost/impressao-conta.php", {
        method: "POST",
        body,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then((r) => {
        if (r.status === HTTP_SUCCESS) return r.json();
        else throw r.json();
      });
    },
  }),
  WhatsApp: () => ({
    async connect(query) {
      return http
        .index(`whatsapp/connect`, query)
        .then((json) => ({ connection: json }))
        .catch(async (e) => ({ connection: null, error: await e }));
    },
    async status(query) {
      return http
        .index(`whatsapp/status`, query)
        .then((json) => ({ status: json }))
        .catch(async (e) => ({ status: null, error: await e }));
    },
    async disconnect(query) {
      return http
        .post("whatsapp/disconnect", query)
        .then((json) => ({ status: json }))
        .catch(async (e) => ({ status: null, error: await e }));
    },
    async screenshot(query) {
      return http
        .index("whatsapp/screenshot", query)
        .then((json) => ({ response: json }))
        .catch(async (e) => ({ response: "", error: await e }));
    },
  }),
});
